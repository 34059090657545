import React, { useState } from 'react';
import { useRecoilCallback, useSetRecoilState } from 'recoil'
import { Tooltip, Typography, Box, IconButton, SvgIcon } from '@material-ui/core'
import { Repeat, Eye, AlertCircle } from 'react-feather'

import { changeAccountingStatus } from './../../Recoil/Selectors/Orders'
import { forceChangeAccountingStatus } from './../../Recoil/Atoms/Orders'
import { refetchUnbilledInvoices } from './../../Recoil/Atoms/Accounting'

import { Table } from '../Table';
import { NewOrder } from './../../Pages/Orders/AddEditViewOrder'
import { SlidingPane } from './../SlidingPane'
import { GeneralModal } from '../Modal';
import { Toast } from '../../Components/Toasts';
import { SignerInfo } from './SignerInfo';
import { useStyles } from './Styles/style';

interface RowData {
  id: string | null;
  order_number: string | null;
  invoice_number: string | null;
  borrower: string | null;
  signer: string | null;
  signer_fee: number | null;
  appointment_date: string | null;
  inv_submitted_on: string | null;
  status: string | null;
  reference_number: string | null;
  payment_status: string | null;
  credential_complete: string | null;
}

const UnbilledInvoicesData = ({
  unbilledInvoices,
  total,
  loading,
  pageInfo,
  setPageInfo
}) => {

  const classes = useStyles();

  const { orderBy, orderType } = pageInfo;
  const [pane, handlePane] = useState(false);
  const [orderId, setOrderId] = useState(undefined);
  const [signerInfoModal, handleSignerInfoModal] = useState(null);
  const [toast, setToast] = useState({ message: '', type: 'success', open: false });
  const forceChangeStatus = useSetRecoilState(forceChangeAccountingStatus)
  const forceRefetchUnbilledInvoices = useSetRecoilState(refetchUnbilledInvoices)

  const changeStatus = useRecoilCallback(({ snapshot }) => async () => {
    forceChangeStatus(n => n + 1)
    const res = await snapshot.getPromise(changeAccountingStatus(orderId));
    if (res?.success) {
      setOrderId(false)
      setToast({ message: 'Status Changed', type: 'success', open: true });
      forceRefetchUnbilledInvoices(n => n + 1)
    } else {
      setToast({ message: res?.result?.error || 'Error occurred', type: 'error', open: true });
    }
  });

  const columns = [
    {
      field: 'f_loan_number',
      headerName: 'Loan/Order #',
      cellClassName: 'datagrid-cell',
      headerClassName: 'datagrid-header',
    },
    {
      field: 'billRefNumber',
      headerName: 'Invoice #',
      cellClassName: 'datagrid-cell',
      headerClassName: 'datagrid-header',
      renderCell: ({ row }) => (
        <Tooltip title={row?.billRefNumber || `${String(row?.w_Borrower_Last_Name.substring(0, 20 - String(row?.Id).length)).trim()}${row?.Id}`}>
          <Typography>
            {row?.billRefNumber || `${String(row?.w_Borrower_Last_Name.substring(0, 20 - String(row?.Id).length)).trim()}${row?.Id}`}
          </Typography>
        </Tooltip>
      )
    },
    {
      field: 'f_borrower_first_name',
      headerName: 'Borrower',
      cellClassName: 'datagrid-cell',
      headerClassName: 'datagrid-header',
      renderCell: ({ row }) => (
        <Typography>{row?.f_borrower_first_name || ''} {' '} {row?.f_borrower_last_name || ''}</Typography>
      )
    },
    {
      field: 'client_name',
      headerName: 'Client',
      cellClassName: 'datagrid-cell',
      headerClassName: 'datagrid-header',
      renderCell: ({ row }) => (
        <Tooltip title={row?.client?.clientName || ''}>
          <Typography>{row?.client?.clientName || ''}</Typography>
        </Tooltip>
      )
    },
    {
      field: 'division',
      headerName: 'Division',
      cellClassName: 'datagrid-cell',
      headerClassName: 'datagrid-header',
      renderCell: ({ row }) => (
        <Tooltip title={row?.division?.divisionName || ''}>
          <Typography>{row?.division?.divisionName || ''}</Typography>
        </Tooltip>
      )
    },
    {
      field: 'f_signer_name',
      headerName: 'Signer',
      cellClassName: 'datagrid-cell',
      headerClassName: 'datagrid-header',
      renderCell: ({ row }) => (
        <Typography
          className={classes.cursorPointer}
          color='primary'
          variant='body1'
          key={row.f_signer_name}
          onClick={() => handleSignerInfoModal(
            { ...row?.signer, w_Loan_Number: row?.w_Loan_Number }
            || { w_Loan_Number: row?.w_Loan_Number })
          }
        >
          {row.f_signer_name}
        </Typography>
      )
    },
    {
      field: 'bill_amount',
      headerName: 'Lender Fee',
      cellClassName: 'datagrid-cell',
      headerClassName: 'datagrid-header',
    },
    {
      field: 'f_appointment_date',
      headerName: 'Appointment Date',
      cellClassName: 'datagrid-cell',
      headerClassName: 'datagrid-header',
    },
    {
      field: 'f_status_web',
      headerName: 'Status',
      cellClassName: 'datagrid-cell',
      headerClassName: 'datagrid-header',
    },
    {
      field: 'payment_status',
      headerName: 'Payment Status',
      cellClassName: 'datagrid-cell',
      headerClassName: 'datagrid-header',
    },
    {
      field: 'credentials_complete',
      headerName: 'Credentials Status',
      cellClassName: 'datagrid-cell',
      headerClassName: 'datagrid-header',
      renderCell: ({ row }) => (
        <Tooltip title={row?.signer?.credentials_complete || ''}>
          <Typography>{row?.signer?.credentials_complete || ''}</Typography>
        </Tooltip>
      )
    },
    {
      field: 'action',
      headerName: 'Action',
      cellClassName: 'datagrid-cell',
      headerClassName: 'datagrid-header',
      renderCell: ({ row }) => {
        return (
          <Box display='flex' flexDirection='column'>
            <Tooltip title='View'>
              <IconButton
                color='inherit'
                onClick={() => handlePane(row?.Id)}
              >
                <SvgIcon fontSize='small' color='primary'>
                  <Eye />
                </SvgIcon>
              </IconButton>
            </Tooltip>
            <Tooltip title='Change Accounting Status'>
              <IconButton
                color='inherit'
                onClick={() => setOrderId(row?.Id)}
              >
                <SvgIcon fontSize='small' color='primary'>
                  <Repeat />
                </SvgIcon>
              </IconButton>
            </Tooltip>
          </Box>
        )
      }
    }
  ];

  const handlePage = (e: unknown, newPage: number) => {
    setPageInfo({
      ...pageInfo,
      page: newPage
    });
  };

  const handleRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPageInfo({
      ...pageInfo,
      limit: parseInt(e.target.value, 10)
    });
  };

  const handleSort = (e: any) => {
    // if (e.sortModel &&e.sortModel[0].field) {
    //   const { field, sort } = e.sortModel[0]

    //   setPageInfo({
    //     ...pageInfo,
    //     orderBy: field,
    //     orderType: sort,
    //   });
    // }
  };

  const rows = unbilledInvoices;

  return (
    <>
      <SlidingPane
        title={'View Order'}
        open={pane}
        handlePane={handlePane}
        width='95%'
      >
        <NewOrder id={pane} justView={true} />
      </SlidingPane>

      <GeneralModal
        title='Change Accounting Status'
        maxWidth='sm'
        open={orderId}
        openModal={setOrderId}
        handleSubmit={changeStatus}
        submitLabel='Confirm'
      >
        <Box display='flex' flexDirection='column' alignItems='center'>
          <SvgIcon fontSize='large' color='error'>
            <AlertCircle />
          </SvgIcon>
          <Typography variant='h2'>PacDocSign</Typography>
          <Typography variant='body2'>Do you wish to change accounting status?</Typography>
        </Box>
      </GeneralModal>
      <Table
        customRowHeight={125}
        sortModel={
          [{ field: orderBy, sort: orderType }]
        }
        useCustomStyles={false}
        loading={loading}
        columns={columns}
        rows={rows}
        total={total}
        pageInfo={pageInfo}
        handlePage={handlePage}
        handleRowsPerPage={handleRowsPerPage}
        onSortingChange={handleSort}
        rowsPerPageOptions={[10, 50, 80]}
      />
      <Toast message={toast.message} type={toast.type} open={toast.open} setToast={setToast} />
      <SignerInfo
        signerInfoModal={signerInfoModal}
        handleSignerInfoModal={handleSignerInfoModal} />
    </>
  )
};

export { UnbilledInvoicesData };
