import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSetRecoilState, useRecoilState } from 'recoil';
import { Box, IconButton, Tooltip } from '@material-ui/core';
import { Copy, Edit, Lock, Unlock, Repeat, PhoneCall, XCircle, Mail, /*Eye,*/ File } from 'react-feather';

import { currentOrder } from '../../Recoil/Atoms/Orders';
import { dialog as dialogState } from '../../Recoil/Atoms/Modals';

import { NewOrder } from './../../Pages/Orders/AddEditViewOrder'
import { SlidingPane } from './../SlidingPane'

const OrdersTableActions = ({
  row, handleCancelOrder, handleChangeStatus, handleLockOrder, handleChangeCallStatus, handleRequestDoc, handleNotes
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [order, setOrder] = useState(row);
  const [pane, handlePane] = useState(false);
  const [userRole, setUserRole] = useState(JSON.parse(localStorage.getItem('user')));

  const [dialog, setDialog] = useRecoilState(dialogState)
  const setCurrentOrderData = useSetRecoilState(currentOrder);

  const { f_status_web: status } = order

  useEffect(() => {
    setOrder(row)
    let getRole = JSON.parse(localStorage.getItem('user'))
    setUserRole(getRole);
  }, [row])
  const handleCancelClick = () => {
    setCurrentOrderData(row)
    setDialog({
      ...dialog,
      open: true,
      type: 'cancel',
      title: 'Cancel order',
      submitLabel: 'Yes',
      handleSubmit: handleCancelOrder,
    })
  }

  const handleChangeStatusClick = () => {
    setCurrentOrderData(row)
    setDialog({
      ...dialog,
      open: true,
      type: 'change_status',
      title: 'Order status',
      submitLabel: 'Submit',
      handleSubmit: handleChangeStatus,
    })
  }

  const handleCallStatusClick = () => {
    setCurrentOrderData(row)
    setDialog({
      ...dialog,
      open: true,
      type: 'change_call_status',
      title: 'Call Status',
      submitLabel: 'Submit',
      handleSubmit: handleChangeCallStatus,
    })
  }

  const handleLockClick = () => {
    setCurrentOrderData({...row, order_locked: isLocked ? 'Y' : 'N'});
    setDialog({
      ...dialog,
      open: true,
      type: 'lock',
      title: isLocked ? 'Lock order' : 'Unlock order',
      submitLabel: 'Yes',
      handleSubmit: ()=> { setIsLocked(!isLocked); handleLockOrder() },
    })
  }

  const isCancelled = (s: string) => s !== 'Cancelled';
  const [isLocked, setIsLocked] = useState(order?.order_locked === 'Y') // order?.order_locked === 'Y';

  return (
    <Box display='flex' width='100%' flexWrap='wrap' >
      <Tooltip title='Edit'>
        <IconButton
          size='small'
          color='primary'
          onClick={()=>{ handlePane(row?.Id) } }
        >
          <Edit width='25' height='25' />
        </IconButton>
      </Tooltip>
      { location?.pathname !== '/orders-monitoring' && isCancelled(status) &&
        <Tooltip title='Cancel'>
          <IconButton size='small' color='primary' onClick={handleCancelClick}>
            <XCircle width='25' height='25' />
          </IconButton>
        </Tooltip>
      }
      { location?.pathname !== '/orders-monitoring' && 
        <Tooltip title={isLocked ? 'Unlock' : 'Lock'}>
          <IconButton disabled={userRole?.role?.name !== 'Accountant'} size='small' color='primary' onClick={handleLockClick}>
            {isLocked ? <Lock width='25' height='25' /> : <Unlock width='25' height='25' />}
          </IconButton>
        </Tooltip>
      }
      { location?.pathname !== '/orders-monitoring' &&
        <Tooltip title='Duplicate Order'>
          <IconButton size='small' color='primary' onClick={() => navigate(`/orders/duplicate/${row?.Id}`)}>
            <Copy width='25' height='25' />
          </IconButton>
        </Tooltip>
      }
      <Tooltip title='Change order status'>
        <IconButton size='small' color='primary' onClick={handleChangeStatusClick}>
          <Repeat width='25' height='25' />
        </IconButton>
      </Tooltip>
      {/* { location?.pathname === '/orders-monitoring' &&
        <Tooltip title='View Order'>
          <IconButton size='small' color='primary' onClick={() => history.push(`/orders/view/${row?.Id}`)}>
            <Eye width='25' height='25' />
          </IconButton>
        </Tooltip>
      } */}
      { location?.pathname === '/orders-monitoring' &&
        <Tooltip title='Change Call Status'>
          <IconButton size='small' color='primary' onClick={handleCallStatusClick}>
            <PhoneCall width='25' height='25' />
          </IconButton>
        </Tooltip>
      }
      { location?.pathname !== '/orders-monitoring' &&
        <Tooltip title='Request Documents'>
          <IconButton size='small' color='primary' onClick={() => handleRequestDoc(row)}>
            <Mail width='25' height='25' />
          </IconButton>
        </Tooltip>
      }
      { location?.pathname === '/orders-monitoring' &&
        <Tooltip title='Add Notes'>
          <IconButton size='small' color='primary' onClick={() => handleNotes(true, row)}>
            <File width='25' height='25' />
          </IconButton>
        </Tooltip>
      }

      <SlidingPane
        title={'Edit Order'}
        open={pane}
        handlePane={handlePane}
        width='95%'
      >
        <NewOrder id={pane} />
      </SlidingPane>
    </Box>
  )
};

export { OrdersTableActions };
