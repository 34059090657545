import React, { useState } from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button
} from '@material-ui/core';
import moment from 'moment-timezone'
import clsx from 'clsx';
import { useRecoilState, useRecoilValueLoadable, useRecoilCallback } from 'recoil';
import { states } from '../../Common/States';


import { useStyles } from './Styles/Actions';
import { getClientNames } from '../../Recoil/Selectors/Clients';
import { getDivisionsByClientForRangeReport, getAllDivisions } from '../../Recoil/Selectors/Divisions';
import { getContactByDevisionForRangeReport, getAllContactsNames } from '../../Recoil/Selectors/Contacts';
import { sortASC } from '../../Common/Utilities/helper';

import { marginStyles } from '../../Common/Styles/Margins';

import { RangeDateRange } from './RangeDateRange';
import { rangeReportsPagination, rangeReportsPaginationPdf, rangeReportsPaginationCsv } from 'src/Recoil/Atoms/Reports';
import { getRangeReportPdf, getRangeReportCsv } from 'src/Recoil/Selectors/Reports';

const RangeReportActions = (props) => {
  const classes = useStyles();
  const marginClasses = marginStyles();
  const clientsData: any = useRecoilValueLoadable(getClientNames)
  const clientsNames = clientsData?.contents?.result || []
  const clientsNamesList = sortASC(clientsNames, 'clientName').map(client =>
    <MenuItem key={client.clientID} value={client.clientID}>{client.clientName}</MenuItem>
  );
  const [page, setPage] = useRecoilState(rangeReportsPagination)
  const [pagePdf, setPagePdf] = useRecoilState(rangeReportsPaginationPdf)
  const [pageCsv, setPageCsv] = useRecoilState(rangeReportsPaginationCsv)
  const [loading, setLoading] = useState(false)

  const allContactRequest: any = useRecoilValueLoadable(getAllContactsNames)
  const allContactNames = allContactRequest?.contents?.result?.rows || []

  const contactsData: any = useRecoilValueLoadable(getContactByDevisionForRangeReport)
  const contactsNames = contactsData?.contents?.result?.rows || []
  const contactsNamesList = sortASC((page.divisionID ? contactsNames : allContactNames), 'Contact_First_Name').map(
    contact =>
      <MenuItem key={contact.Id} value={contact.Id}>
        {`${contact.Contact_First_Name} ${contact.Contact_Last_Name}`}
      </MenuItem>
  )



  const allDivisionsRequest: any = useRecoilValueLoadable(getAllDivisions)
  const allDivisionNames = allDivisionsRequest?.contents?.result?.rows || []

  const divisionsData: any = useRecoilValueLoadable(getDivisionsByClientForRangeReport)
  const divisionsNames = divisionsData?.contents?.result?.rows || []
  const divisionsNamesList = sortASC(page.clientID ? divisionsNames : allDivisionNames, 'divisionName').map(division =>
    <MenuItem key={division.divisionID} value={division.divisionID}>{division.divisionName}</MenuItem>
  );

  const handleDates = (start, end) => {
    setPage({
      ...page,
      start: start.format('MM/DD/YYYY'),
      end: end.format('MM/DD/YYYY'),
    })
  };

  const clearDates = () => {
    setPage({
      ...page,
      start: moment().format('MM/DD/YYYY'),
      end: moment().format('MM/DD/YYYY'),
      limit: 20,
      page: 1
    })
  };



  const getScanback = useRecoilCallback(({ snapshot }) => async (filename: any) => {
    setPagePdf({ ...pagePdf, ...page })
    setLoading(true)
    const res: any = await snapshot.getPromise(
      getRangeReportPdf({
        action: 'view/download',
      }))
    if (res.success) {
      const fetchedFile = window.URL.createObjectURL(res.result);
      const tempLink = document.createElement('a');
      tempLink.href = fetchedFile;
      tempLink.setAttribute('download', "Range Report.pdf");
      tempLink.click();
    }
    setLoading(false)
  })

  const getScanbackCsv = useRecoilCallback(({ snapshot }) => async (filename: any) => {
    setPageCsv({ ...pageCsv, ...page })
    setLoading(true)
    const res: any = await snapshot.getPromise(
      getRangeReportCsv({
        action: 'view/download',
      }))
    if (res.success) {
      const fetchedFile = window.URL.createObjectURL(res.result);
      const tempLink = document.createElement('a');
      tempLink.href = fetchedFile;
      tempLink.setAttribute('download', "Range Report.csv");
      tempLink.click();
    }
    setLoading(false)
  })

  const handleFilter = (type: string, value: any) => {
    setPage({
      ...page,
      [type]: value,
      limit: 20,
      page: 1
    })
  };

  return (
    <Box my={2} display='flex'>
      <Box width={'20%'} mr={1}>
        <RangeDateRange handleDates={handleDates} clearDates={clearDates} />
      </Box>
      <FormControl
        className={clsx(
          marginClasses.mt0,
          marginClasses.mb0,
          marginClasses.mr1,
          classes.select
        )}
        margin='dense'
        variant='outlined'
      >
        <InputLabel>Clients</InputLabel>
        <Select
          label='Clients'
          defaultValue=''
          onChange={(e) => handleFilter('clientID', e.target.value)}>
          <MenuItem value={null}>All Clients</MenuItem>
          {clientsNamesList}
        </Select>
      </FormControl>
      <FormControl
        className={clsx(
          marginClasses.mt0,
          marginClasses.mb0,
          marginClasses.mr1,
          classes.select
        )}
        margin='dense'
        variant='outlined'
      >
        <InputLabel>Divisions</InputLabel>
        <Select
          label='Divisions'
          defaultValue=''
          onChange={(e) => handleFilter('divisionID', e.target.value)}>
          {divisionsNamesList}
        </Select>
      </FormControl>
      <FormControl
        className={clsx(
          marginClasses.mt0,
          marginClasses.mb0,
          marginClasses.mr1,
          classes.select
        )}
        margin='dense'
        variant='outlined'
      >
        <InputLabel>Contacts</InputLabel>
        <Select
          label='Contacts'
          defaultValue=''
          onChange={(e) => handleFilter('Contact_ID', e.target.value)}>
          {contactsNamesList}
        </Select>
      </FormControl>
      <FormControl
        className={clsx(
          marginClasses.mt0,
          marginClasses.mb0,
          marginClasses.mr1,
          classes.select
        )}
        margin='dense'
        variant='outlined'
      >
        <InputLabel>State</InputLabel>
        <Select
          label='State'
          fullWidth
          onChange={(e) => handleFilter('w_Signing_State', e.target.value)}
        >
          {states.map((state) =>
            <MenuItem key={`borrower-${state.code}`} value={state.code}>
              {state.name}
            </MenuItem>
          )}
        </Select>
      </FormControl>
      <Button className={clsx(
        marginClasses.mt0,
        marginClasses.mb0,
        marginClasses.mr1,
        classes.btnNoWrap,
      )} style={{ whiteSpace: "nowrap", minWidth: "max-content" }} color='primary' variant='contained' size='small'>
        Search
      </Button>
      <Button className={clsx(
        marginClasses.mt0,
        marginClasses.mb0,
        marginClasses.mr1,
        classes.btnNoWrap,
      )} style={{ whiteSpace: "nowrap", minWidth: "max-content" }} variant="contained" color="primary" size='small' onClick={getScanback}
        disabled={loading}
      >
        Download Report PDF
      </Button>
      <Button className={clsx(
        marginClasses.mt0,
        marginClasses.mb0,
        marginClasses.mr1,
        classes.btnNoWrap,
      )} color='primary' variant='contained' size='small' onClick={getScanbackCsv}
        disabled={loading}
      >Download Report CSV</Button>
    </Box>
  );
};

export { RangeReportActions };
