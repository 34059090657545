import React, { useState } from 'react';
import { Grid, Typography, TextField, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

import ReactGoogleMapLoader from 'react-google-maps-loader';
import ReactGooglePlacesSuggest from 'react-google-places-suggest';

import { states } from '../../../Common/States';

import { useStyles } from '../Styles/Fields';

const ClientAddress = ({ values, errors, touched, handleChange, handleBlur, setFieldValue }) => {
  const classes = useStyles();
  const [addressSearch, handleAddressSearch] = useState('');

  return (
    <>
      <Grid item sm={12}>
        <ReactGoogleMapLoader
          params={{
            key: 'AIzaSyBUc7jJ_PvS8fORd-6-Lju_YWR-CgX6cis',
            libraries: 'places,geocode',
          }}
          render={googleMaps =>
            googleMaps && (
              <ReactGooglePlacesSuggest
                googleMaps={googleMaps}
                autocompletionRequest={{ input: addressSearch }}
                onSelectSuggest={geocodedPrediction => {
                  let address = '';
                  handleAddressSearch('');
                  geocodedPrediction.address_components.forEach(item => {
                    if (item.types[0] === 'locality') {
                      setFieldValue('city', item.short_name);
                    }
                    if (item.types[0] === 'administrative_area_level_1') {
                      setFieldValue('state', item.short_name);
                    }
                    if (item.types[0] === 'postal_code') {
                      setFieldValue('zip', item.short_name);
                    }
                    if (item.types[0] === 'street_number' || item.types[0] === 'premise') {
                      address = item.long_name;
                    }
                    if (item.types[0] === 'route' || item.types[0] === 'political') {
                      address = `${address} ${item.long_name}`;
                    }
                    setFieldValue('address', address);
                  })
                }}
                textNoResults='Address Not Found'
                customRender={prediction => (
                  <Typography variant='body2' className={classes.addressItem}>
                    { prediction ?
                      prediction.description :
                      'Address Not Found'
                    }
                  </Typography>
                )}
              >
                <TextField
                  label='Address'
                  name='address'
                  variant='outlined'
                  size='small'
                  fullWidth
                  value={values.address}
                  onChange={(e) => {
                    handleChange(e);
                    handleAddressSearch(e.target.value);
                  }}
                  onBlur={handleBlur}
                  helperText={touched.address && errors.address}
                  error={Boolean(touched.address && errors.address)}
                  inputProps={{
                    autoComplete: 'none'
                  }}
                />
              </ReactGooglePlacesSuggest>
            )
          }
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label='City'
          name='city'
          variant='outlined'
          size='small'
          fullWidth
          value={values.city}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={touched.city && errors.city}
          error={Boolean(touched.city && errors.city)}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl className={classes.removeSelectMargin} margin='dense' variant='outlined'>
          <InputLabel>State</InputLabel>
          <Select
            name='state'
            fullWidth
            value={values.state}
            onChange={handleChange}
            label='State'
          >
            {states.map((state) =>
              <MenuItem key={`borrower-${state.code}`} value={state.code}>
                {state.name}
              </MenuItem>
            )}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label='Zip Code'
          name='zip'
          variant='outlined'
          size='small'
          fullWidth
          value={values.zip}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={touched.zip && errors.zip}
          error={Boolean(touched.zip && errors.zip)}
        />
      </Grid>
    </>
  );
}

export { ClientAddress };
