import React, { useEffect, useState } from 'react';
import { Grid, FormControl, InputLabel, Select, TextField, MenuItem } from '@material-ui/core';

import { useStyles } from '../Styles/Fields';
import { getAllSignerUsers } from '../../../Recoil/Selectors/User';
import { useRecoilCallback } from 'recoil';

const TrackingInfo = ({ isViewOnlyMode, location, values, errors, touched, handleChange, handleBlur }) => {
    const classes = useStyles();
    const [signerUsers, setSignerUsers] = useState([]);
    const fetchSubSigners = useRecoilCallback(({ snapshot }) => async () => {
        const res: any = await snapshot.getPromise(getAllSignerUsers(values?.f_signer_id));
        setSignerUsers(res.result);
    });
    useEffect(() => {
        if (values?.f_signer_id) {
            fetchSubSigners();
        }
    }, [values?.f_signer_id]);

    return (
        <>
            <Grid item xs={12}>
                <FormControl className={classes.removeSelectMargin} margin='dense' variant='outlined'>
                    <InputLabel>Tracking Status</InputLabel>
                    <Select
                        disabled={isViewOnlyMode}
                        onChange={handleChange}
                        label='Tracking Status'
                        name={`${location}.S2C_Tracking_Status`}
                        fullWidth
                        value={values.S2C_Tracking_Status || ''}
                    >
                        <MenuItem value='DE'>Delivered</MenuItem>
                        <MenuItem value='HD'>Hand Delivered</MenuItem>
                        <MenuItem value='BD'>Borrower Delivered</MenuItem>
                        <MenuItem value='LB'>Left With Borrower</MenuItem>
                        <MenuItem value='IT'>In Transit</MenuItem>
                        <MenuItem value='SC'>See Comments</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    disabled={isViewOnlyMode}
                    label='Tracking Number'
                    name={`${location}.w_Return_Tracking`}
                    variant='outlined'
                    size='small'
                    fullWidth
                    value={values.w_Return_Tracking}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={(touched[location] && errors[location]) && errors[location].w_Return_Tracking}
                    error={Boolean((touched[location] && errors[location]) && errors[location].w_Return_Tracking)}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    disabled={isViewOnlyMode}
                    label='Note'
                    name={`${location}.S2C_Tracking_Note`}
                    variant='outlined'
                    size='small'
                    fullWidth
                    value={values.S2C_Tracking_Note}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={(touched[location] && errors[location]) && errors[location].S2C_Tracking_Note}
                    error={Boolean((touched[location] && errors[location]) && errors[location].S2C_Tracking_Note)}
                />
            </Grid>
            {
                values?.signer?.Signer_Account_Type === 'COMPANY' ?
                    <Grid item xs={12}>
                        <FormControl className={classes.removeSelectMargin} margin='dense' variant='outlined'>
                            <InputLabel>Select Sub Signer</InputLabel>
                            <Select
                                onChange={handleChange}
                                label='Tracking Status'
                                name={`${location}.Sub_Signer_Id`}
                                fullWidth
                                value={values?.Sub_Signer_Id || ''}
                            >
                                {
                                    signerUsers.map((signer, index) => {
                                        return (
                                            <MenuItem value={signer?.signer_id} key={index}>
                                                {signer?.Signer_First} {signer?.Signer_Last}
                                            </MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    : <></>
            }
        </>
    );
}

export { TrackingInfo };
