import React, { useState } from 'react';
import { useRecoilState, /*useSetRecoilState*/ } from 'recoil';
import { Box, IconButton, Tooltip, Typography, SvgIcon } from '@material-ui/core';
import { Eye } from 'react-feather';
import moment from 'moment';
// import { DateTime } from "luxon";

import { clientInvoicesPagination } from '../../Recoil/Atoms/ClientInvoices';
// import { currentOrder } from '../../Recoil/Atoms/Orders';
// import { GeneralModal } from '../../Components/Modal/'
// import { ViewClientInvoices } from "./ViewClientInvoices"
import { NewOrder } from './../../Pages/Orders/AddEditViewOrder'
import { SlidingPane } from './../SlidingPane'
import { Table } from '../Table';
import { SignerInfo } from './SignerInfo';

import { useStyles } from './Styles/style';

const ClientInvoicesData = ({
  setRefetchClientInvoices,
  rows,
  total,
  loading,
}) => {
  const classes = useStyles();

  const [pageInfo, setPageInfo] = useRecoilState(clientInvoicesPagination);
  const { orderBy, orderType } = pageInfo;
  const [pane, handlePane] = useState(false);
  const [signerInfoModal, handleSignerInfoModal] = useState(null);

  // const [viewOrderDetails, setViewOrderDetails] = useState(false)
  //    const setCurrentOrder = useSetRecoilState(currentOrder);

  const actionsFormatter = params => {
    return (
      <Box display='flex'>
        {/* <GeneralModal
          maxWidth='sm'
          title='View Invoice Details'
          noSubmit
          open={viewOrderDetails}
          openModal={setViewOrderDetails}
        >
          <ViewClientInvoices invoiceDetail={params} />
        </GeneralModal>
        <Tooltip title='View Invoice'>
          <IconButton size='small' color='primary' onClick={(e) => {
            e.stopPropagation();
            setViewOrderDetails(true)
            setCurrentOrder({
              ...rows,
              w_Appointment_Time: moment(
                rows.w_Appointment_Time,
                'hh:mm A'
              )
            });
          }}>
             <SvgIcon fontSize='small' color='primary'>
                <Eye />
              </SvgIcon>
          </IconButton>
        </Tooltip> */}
        <Tooltip title='View Order'>
            <IconButton
            color='inherit'
            onClick={()=> handlePane(params?.row?.Id) }
            >
              <SvgIcon fontSize='small' color='primary'>
                <Eye />
              </SvgIcon>
            </IconButton>
          </Tooltip>
   
      </Box>
    );
  }

  const columns = [{
    field: 'w_Loan_Number',
    headerName: 'Loan/Order #',
    headerClassName: 'datagrid-header',
    cellClassName: 'datagrid-cell-custom',
    disableColumnMenu: true,
  }, {
    field: 'Id',
    renderCell: ({ row }) => (
      <div style={{ display: 'block' }}>
        <Typography
          variant='body1'
          variantMapping={{ body1: 'p' }}
          key={row.Id}
        >
         {row.w_Borrower_Last_Name}{row.Id}
        </Typography>
      </div>
    ),
    headerName: 'Invoice #',
    headerClassName: 'datagrid-header',
    cellClassName: 'datagrid-cell-custom',
    disableColumnMenu: true,
  }, {
    field: 'Contact',
    renderCell: ({ row }) => (
      <div style={{ display: 'block' }}>
        <Typography
          variant='body1'
          variantMapping={{ body1: 'p' }}
          key={row.Id}
        >
          {row.contact.Contact_First_Name} {row.contact.Contact_Last_Name}
        </Typography>
      </div>
    ),
    headerName: 'Contact',
    headerClassName: 'datagrid-header',
    cellClassName: 'datagrid-cell-custom',
    disableColumnMenu: true,
  }, {
    field: 'f_signer_name',
    headerName: 'Signer',
    headerClassName: 'datagrid-header',
    cellClassName: 'datagrid-cell-custom',
    disableColumnMenu: true,
    renderCell: ({ row }) => (
        <Typography
          className={classes.cursorPointer}
          color='primary'
          variant='body1'
          variantMapping={{ body1: 'p' }}
          key={row.f_signer_name}
          onClick={() => handleSignerInfoModal(
            { ...row?.signer, w_Loan_Number: row?.w_Loan_Number }
            || { w_Loan_Number: row?.w_Loan_Number })
          }
        >
          {row.f_signer_name}
        </Typography>
    )
  }, {
    field: 'w_sql_appointment_date',
    headerName: 'Appointment Date',
    headerClassName: 'datagrid-header',
    cellClassName: 'datagrid-cell-custom',
    disableColumnMenu: true,
  }, {
    field: 'createdAt',
    headerName: 'Bill Created',
    headerClassName: 'datagrid-header',
    cellClassName: 'datagrid-cell-custom',
    disableColumnMenu: true,
    renderCell: ({row}) => {
      return (
        <div style={{ display: 'block' }}>
          <span>
          {moment(row?.createdAt).format("YYYY-MM-DD")}
          </span>
        </div>
      );
    }
  }, {
    field: 'f_status_web',
    headerName: 'Status',
    headerClassName: 'datagrid-header',
    cellClassName: 'datagrid-cell-custom',
    disableColumnMenu: true,
  },
  {
    field: 'action',
    headerName: 'Action',
    sortable: false,
    disableColumnMenu: true,
    renderCell: actionsFormatter,
    headerClassName: 'datagrid-header',
    cellClassName: 'datagrid-cell-custom',
  }];

  const handlePage = (e: unknown, newPage: number) => {
    setPageInfo({
      ...pageInfo,
      page: newPage
    });
  };

  const handleRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPageInfo({
      ...pageInfo,
      limit: parseInt(e.target.value, 10)
    });
  };

  const handleSort = (e: any) => {
    // if (e.sortModel &&e.sortModel[0].field) {
    //   const { field, sort } = e.sortModel[0]
    //   setPageInfo({
    //     ...pageInfo,
    //     orderBy: field,
    //     orderType: sort,
    //   });
    // }
  };

  return (
    <>
    <SlidingPane
      title={'View Order'}
      open={pane}
      handlePane={handlePane}
      width='95%'
    >
      <NewOrder id={pane} justView={true} />
    </SlidingPane>
    <Table
      useCustomStyles={false}
      customRowHeight={85}
      loading={loading}
      columns={columns}
      rows={rows}
      total={total}
      pageInfo={pageInfo}
      handlePage={handlePage}
      handleRowsPerPage={handleRowsPerPage}
    onSortingChange={handleSort}
    sortModel={[{
      sort: orderType, field: orderBy
    }]}
      />
      <SignerInfo
        signerInfoModal={signerInfoModal}
        handleSignerInfoModal={handleSignerInfoModal}/>
      </>
  );
};

export { ClientInvoicesData };
