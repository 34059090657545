import React from 'react';
import { Grid, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import clsx from 'clsx';

import { marginStyles } from '../../../Common/Styles/Margins';

const BillingCycle = ({ values, handleChange }) => {
  const marginClasses = marginStyles();

  return (
    <Grid item xs={12} md={6}>
      <FormControl className={clsx(marginClasses.mb0, marginClasses.mt0)} margin='dense' variant='outlined'>
        <InputLabel>Billing Cycle</InputLabel>
        <Select
          label='Billing Cycle'
          name='billing_cycle'
          fullWidth
          value={values.billing_cycle}
          onChange={handleChange}
        >
          <MenuItem value='Daily'>Daily</MenuItem>
          <MenuItem value='Semi-Monthly'>Semi-Monthly</MenuItem>
          <MenuItem value='Monthly'>Monthly</MenuItem>
        </Select>
      </FormControl>
    </Grid>
  );
}

export { BillingCycle };
