import React from 'react';
import { useRecoilValueLoadable } from 'recoil'
import { Grid, FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@material-ui/core';

import { getAllContactsByDivision } from './../../../Recoil/Selectors/Contacts'

import { useStyles } from '../Styles/Fields';

const ContactSelection = ({ isViewOnlyMode, location, handleChange, touched, errors, values }) => {
  const classes = useStyles();

  const contactsRequest: any = useRecoilValueLoadable(getAllContactsByDivision);
  const contacts: any = contactsRequest?.contents?.result || []
  const hasError = Boolean(touched[location] && errors[location]) ? errors[location].Contact_ID : ''

  return (
    <Grid item xs={12} md={6}>
      <FormControl className={classes.removeSelectMargin} margin='dense' variant='outlined' error={hasError}>
        <InputLabel>Contacts</InputLabel>
        <Select
          disabled={isViewOnlyMode}
          fullWidth
          label='Contacts'
          defaultValue=''
          error={Boolean((touched[location] && errors[location]) && errors[location].Contact_ID)}
          name={`${location}.Contact_ID`}
          onChange={(e: any) => { handleChange(e) }}
          value={values.Contact_ID}
        >{

            contacts.length > 0 ?
              contacts.map((contact: any) => {
                return (
                  <MenuItem key={contact.Id} value={contact.Id}>{`${contact.Contact_First_Name} ${contact.Contact_Last_Name}`}</MenuItem>
                )
              }) :
              <MenuItem value=''></MenuItem>
          }
        </Select>
        {hasError &&
          <FormHelperText>{hasError}</FormHelperText>}
      </FormControl>
    </Grid>
  );
}

export { ContactSelection };
