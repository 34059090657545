import React, { useState } from 'react';
import { useRecoilState } from 'recoil'

import { Box, IconButton, Tooltip } from '@material-ui/core';
import { FileText, Eye } from 'react-feather';

import { NewOrder } from '../../../Pages/Orders/AddEditViewOrder'
import { SlidingPane } from '../../SlidingPane'
import { getCreateBillPopup } from './../../../Recoil/Atoms/Orders'

const UnbilledOrderDataActions = ({ row, handleCreateOrderBillCurrentRow }) => {

  const [pane, handlePane] = useState(false);
  const [billPopup, setBillPopup] = useRecoilState(getCreateBillPopup)

  const openPopup = () => {
    setBillPopup({
      orderId: row?.Id,
      refetch: billPopup?.refetch + 1
    })
    handleCreateOrderBillCurrentRow(row)
  }

  return (
    <Box display='flex' width='100%'>
      <Tooltip title='View'>
        <IconButton size='small' color='primary' onClick={() => { handlePane(row?.Id) }}>
          <Eye width='25' height='25' />
        </IconButton>
      </Tooltip>

      <Tooltip title='Create Bill'>
        <IconButton
          size='small'
          color='primary'
          onClick={() => openPopup()}
        >
          <FileText width='25' height='25' />
        </IconButton>
      </Tooltip>
      <SlidingPane
        title={'View Invoice'}
        open={pane}
        handlePane={handlePane}
        width='95%'
      >
        <NewOrder id={pane} justView={true} />
      </SlidingPane>
    </Box>
  );
};

export { UnbilledOrderDataActions };
