import React from 'react';
import { useRecoilState } from 'recoil'
import { Typography, Tooltip } from '@material-ui/core'

import { Table } from '../Table';
import { rangeReportsPagination } from './../../Recoil/Atoms/Reports'

const RangeData = (props: any) => {
  const { data, loading, total } = props
  const [pagination, setPagination] = useRecoilState(rangeReportsPagination)

  const columns = [{
    field: 'date',
    headerName: 'Date'
  }, {
    field: 'clientName',
    headerName: 'Client'
  }, {
    field: 'divisionName',
    headerName: 'Division'
  }, {
    field: 'Contact_Last_Name',
    headerName: 'Contact'
  }, {
    field: 'orderCount',
    headerName: 'Total Orders'
  }, {
    field: 'apptCount',
    headerName: 'Total Appointments'
  }, {
    field: 'signedCount',
    headerName: 'Total Signed Orders'
  }, {
    field: 'Contact_Email',
    headerName: 'Processor Emails',
    renderCell: (data: any) => (
      <Tooltip title={data?.row?.Contact_Email}>
        <Typography variant='body2'>{data?.row?.Contact_Email}</Typography>
      </Tooltip>
    )
  }];

  const handlePage = (e: unknown, newPage: number) => {
    setPagination({
      ...pagination,
      page: newPage
    });
  };

  const handleRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPagination({
      ...pagination,
      limit: parseInt(e.target.value, 10)
    });
  };

  return (
    <Table
      loading={loading}
      columns={columns}
      rows={data}
      total={total}
      pageInfo={{ page: pagination.page, limit: pagination.limit }}
      handlePage={handlePage}
      handleRowsPerPage={handleRowsPerPage}
      useCustomStyles={true}
    />
  );
};

export { RangeData };
