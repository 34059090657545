import React, { useEffect, useState } from 'react';
import { useRecoilValueLoadable } from 'recoil'
import { Grid, Button, Typography } from '@material-ui/core';
import moment from 'moment'

import { useStyles } from './../Styles/Fields'

import { getAllContactsByDivision } from './../../../Recoil/Selectors/Contacts'

const AdditionalClientEmail = ({location, values, errors, touched, handleChange, handleBlur, isEditMode }) => {
  
  const contactsRequest: any = useRecoilValueLoadable(getAllContactsByDivision);
  const contacts: any = contactsRequest?.contents?.result || []
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState({ home: '', work: '' })
  const classes = useStyles()
  useEffect(() => {
    if (contacts.length > 0) {
      const contact = contacts.find(contact => { return contact?.Id === values?.Contact_ID });
      setEmail(contact?.Contact_Email || '')
      setPhone({ home: contact?.Contact_Cell || '', work: contact?.Contact_Work_Phone || '' })
    }
  }, [contacts, values])

  return (
    <>
      <Grid item xs={12} md={6}>
        <Button variant="outlined" 
          className={classes.fullHeight}
          
          color="primary" 
          href={`${email !== '' ? `mailto:${email}${
            `?subject=Loan # ${values.w_Loan_Number} - ${values.w_Borrower_First_Name} ${values.w_Borrower_Last_Name} - ${values.w_Appointment_Date} ${moment(values.w_Appointment_Time).format('hh:mm A')}`}` 
            : ''}`
          }
        >
          {email}
        </Button>
      </Grid>
      <Grid item xs={3}>
        <Typography variant='body2' className={classes.fieldHeader}>Contact Phone Number:</Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography variant='body2'>{phone?.home}</Typography>
      </Grid>

      <Grid item xs={3}>
        <Typography variant='body2' className={classes.fieldHeader}>Contact Work Phone Number:</Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography variant='body2'>{phone?.work}</Typography>
      </Grid>
    </>
  );
}

export { AdditionalClientEmail };
