import React from 'react';
import { Grid, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

import { useStyles } from '../Styles/Fields';
import { sortASC } from 'src/Common/Utilities/helper';

const ClientsList = ({ clients, client, getDivisions }) => {

  const classes = useStyles();
  return (
    <Grid item xs={12}>
      <FormControl className={classes.removeSelectMargin} margin='dense' variant='outlined'>
        <InputLabel>Client</InputLabel>
        <Select
          label='Client'
          fullWidth
          defaultValue=''
          value={client}
          onChange={(e: any) => getDivisions(parseInt(e.target.value, 10))}
        >
          {sortASC(clients, 'clientName').map((e) => {
            return (
              <MenuItem key={e.clientID} value={e.clientID}>{e.clientName}</MenuItem>
            )
          })}
        </Select>
      </FormControl>
    </Grid>
  );
}

export { ClientsList };
