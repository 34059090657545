import React from 'react';
import type { FC } from 'react';
import { Box, Card, CardContent, Container, Divider, Typography, SvgIcon, Link } from '@material-ui/core';
import { Lock } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';

import { Page } from '../Components/Page';
import { PasswordForm } from '../Components/Login/PasswordForm';

import { useStyles } from './Styles/Login';

interface HandlePassword {
  submitPassword: (params) => Promise<void>
};

const LoginPassword: FC<HandlePassword> = (props) => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title='Login'>
      <Container className={classes.cardContainer} maxWidth='sm'>
        <Card>
          <CardContent className={classes.cardContent}>
            <div className={classes.currentMethodIcon}>
              <img alt='Auth method' src='/static/Logo.png' />
            </div>
            <Box alignItems='center' display='flex' justifyContent='space-between'>
              <div>
                <Typography color='textPrimary' gutterBottom variant='h2'>
                  Password
                </Typography>
                <Typography variant='body2' color='textSecondary'>
                  Sign in to your dashboard
                </Typography>
              </div>
            </Box>
            <Box mt={3}>
              <PasswordForm submitPassword={props.submitPassword} />
            </Box>
            <Box my={3}>
              <Box display='flex' justifyContent='flex-end'>
                <Link
                  variant='body2'
                  color='textPrimary'
                  component={RouterLink}
                  to='/resetpassword'
                >
                  Forgot Password?
                </Link>
              </Box>
              <Divider />
            </Box>
            <Typography variant='body2' color='textSecondary' align='justify'>
              <SvgIcon className={classes.bannerChip} fontSize='small' color='primary'>
                <Lock />
              </SvgIcon>
              PacDocSign uses 128-bit Secure Sockets Layer (SSL) technology. This means that when you are on our secured
              website the data transferred between PacDocSign and you is encrypted and cannot be viewed by any
              other party. The security of your Borrower personal information is of the utmost importance to PacDocSign.
            </Typography>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export { LoginPassword };
