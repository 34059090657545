import React from 'react';
import { useRecoilValueLoadable, useSetRecoilState } from 'recoil'
import { Grid, FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@material-ui/core';

import { selectedDivision } from './../../../Recoil/Atoms/Divisions'
import { getAllDivisionByClient } from './../../../Recoil/Selectors/Divisions'

import { useStyles } from '../Styles/Fields';

const DivisionSelection = ({ isViewOnlyMode, location, handleChange, touched, errors, setFieldValue, values }) => {
  const classes = useStyles();

  const divisionRequest: any = useRecoilValueLoadable(getAllDivisionByClient);
  const divisions: any = divisionRequest?.contents?.result || []
  const setDivision: any = useSetRecoilState(selectedDivision)
  const hasError = Boolean(touched[location] && errors[location]) ? errors[location].division_id : ''
  return (
    <Grid item xs={12} md={6}>
      <FormControl className={classes.removeSelectMargin} margin='dense' variant='outlined' error={hasError}>
        <InputLabel>Divisions</InputLabel>
        <Select
          disabled={isViewOnlyMode}
          fullWidth
          label='Divisions'
          error={Boolean((touched[location] && errors[location]) && errors[location].division_id)}
          name={`${location}.division_id`}
          value={values.division_id}
          onChange={(e) => {
            handleChange(e)
            setDivision(e.target.value)
            setFieldValue(`${location}.Contact_ID`, '')
          }}
          defaultValue=''
        >{
            divisions.length > 0 ?
              divisions.map((division: any) => {
                return (
                  <MenuItem key={division.divisionID} value={division.divisionID}>{division.divisionName}</MenuItem>
                )
              }) :
              <MenuItem value=''></MenuItem>
          }
        </Select>
        {hasError &&
          <FormHelperText>{hasError}</FormHelperText>}
      </FormControl>
    </Grid>
  );
}

export { DivisionSelection };
